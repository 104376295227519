import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import { FirebaseContext } from "./Firebase";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignInVerificationPage from "./pages/SignInVerificationPage";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
// import SubscriptionListPage from "./pages/SubscriptionListPage";

function App() {
  const firebase = useContext(FirebaseContext);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    if (firebase) {
      firebase.auth.onAuthStateChanged(function (user) {
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
        }
      });

      // firebase.auth
      //   .signInAnonymously()
      //   .then(() => console.log("Signed in OK"))
      //   .catch((err) => console.error("Sing in ERR", err));
    }
  }, [firebase]);

  return (
    <Router>
      <>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Container className="vh-100">
          {currentUser === undefined && (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Spinner animation="border" variant="light" />
            </div>
          )}
          {currentUser !== undefined && (
            <Switch>
              <PrivateRoute exact path="/" state={{ user: currentUser }}>
                <HomePage user={currentUser} />
              </PrivateRoute>
              <PublicRoute exact path="/signin" state={{ user: currentUser }}>
                <SignInPage user={currentUser} />
              </PublicRoute>
              <PublicRoute
                exact
                path="/login/verify"
                state={{ user: currentUser }}
              >
                <SignInVerificationPage user={currentUser} />
              </PublicRoute>
            </Switch>
          )}
        </Container>
      </>
    </Router>
  );
}

export default App;
