import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

import { FirebaseContext } from "../../Firebase";
import SubscriptionCard from "./components/SubscriptionCard";
import { useVerificationCodeGenerator } from "./hooks";
import Logo from "../../logo.png";

const HomePage = ({ user }) => {
  const firebase = useContext(FirebaseContext);
  const { createVerificationCode, creatingCode } = useVerificationCodeGenerator(
    firebase
  );

  const [myActiveSubscriptions, setMyActiveSubscriptions] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (firebase && user) {
      return firebase.db
        .collection("SubsriptionPurchases")
        .where("userID", "==", user.uid)
        .where("isActive", "==", true)
        .onSnapshot((qSnapshot) => {
          const dataList = [];
          qSnapshot.forEach((doc) => {
            // check if the subscription purchase is  in the allowed timeframe
            const data = doc.data();
            const currDate = moment();
            if (
              currDate.isBetween(
                moment.unix(data.subscriptionStartDate.seconds),
                moment.unix(data.subscriptionEndDate.seconds),
                "day",
                []
              )
            ) {
              dataList.push({ id: doc.id, ...data });
            }
          });

          setMyActiveSubscriptions([...dataList]);
          setIsInitialized(true);
        });
    } else {
      setMyActiveSubscriptions([]);
    }
  }, [user, firebase]);

  const [
    currActiveVerificationCodes,
    setCurrActiveVerificationCodes,
  ] = useState([]);
  const [loadingVerCodes, setLoadingVerCodes] = useState(true);
  useEffect(() => {
    if (myActiveSubscriptions.length > 0) {
      // const currActiveSubcription = myActiveSubscriptions[0];
      return firebase.db
        .collectionGroup("verificationCodes")
        .where("isActive", "==", true)
        .where("userID", "==", user.uid)
        .onSnapshot((qSnapshot) => {
          setLoadingVerCodes(true);
          const dData = {};
          qSnapshot.forEach((vCode) => {
            const vCodeData = vCode.data();
            console.log("subscriptionID", vCodeData.subscriptionID);
            const codesForPurchaseID = dData[vCodeData.subscriptionID] || [];
            dData[vCodeData.subscriptionID] = [
              { id: vCode.id, ...vCodeData },
              ...codesForPurchaseID,
            ];
          });
          console.log(dData);
          setCurrActiveVerificationCodes(dData);
          setLoadingVerCodes(false);
        });
    }
  }, [firebase.db, myActiveSubscriptions]);

  if (!isInitialized)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="light" />
      </div>
    );

  return (
    <Row className="vh-100" align-items-center justify-content-center>
      {myActiveSubscriptions.map((item) => (
        <Col xs={12} lg={6}>
          <SubscriptionCard
            data={item}
            verificationCode={currActiveVerificationCodes[item.id]}
            createVerificationCode={(subscriptionID) =>
              createVerificationCode(subscriptionID, user.uid)
            }
            loading={loadingVerCodes}
            creatingCode={creatingCode}
          />
        </Col>
      ))}
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 7, offset: 2 }}
        lg={{ span: 4, offset: 4 }}
      >
        {myActiveSubscriptions.length === 0 && (
          <div className="d-flex flex-column   justify-content-between vh-100">
            <div className="d-flex flex-column">
              <div
                className="align-self-center"
                style={{ marginTop: 12 + "rem", marginBottom: 4 + "rem" }}
              >
                <img
                  className="align-self-center"
                  src={Logo}
                  width="178"
                  height="15"
                  alt="logo"
                />
              </div>

              <a
                style={{ color: "4e4e4e" }}
                className="btn btn-poke"
                href="http://mypoke.ru/lunchsubscribe"
              >
                КУПИТЬ ПОДПИСКУ
              </a>
            </div>
            <div
              className="align-self-center"
              style={{ marginBottom: 4 + "rem" }}
            >
              <a
                style={{ fontSize: 1 + "rem", color: "#4e4e4e" }}
                href="https://mypoke.ru"
                className="font-weight-bold"
              >
                mypoke.ru
              </a>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default HomePage;
