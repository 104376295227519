import { useEffect, useState } from "react";

const useSignInWithEmailLink = (URL, email, removeEmail, firebase) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (firebase && email) {
      firebase
        .signInWithEmailLink(email, URL)
        .then(function (result) {
          removeEmail();
          setError(false);
          setLoading(false);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
        });
    }
  }, [email, URL, firebase]);
  return { loading, error };
};
export { useSignInWithEmailLink };
