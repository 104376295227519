import React, { useEffect, useState, useContext } from "react";

export const useVerificationCodeGenerator = (firebase) => {
  const [creatingCode, setCreatingCode] = useState(false);
  const createVerificationCode = async (subscriptionID, userID) => {
    if (firebase) {
      setCreatingCode(true);
      const r = await firebase.db
        .collection(`SubsriptionPurchases/${subscriptionID}/verificationCodes`)
        .add({
          createdAt: firebase.FieldValue.serverTimestamp(),
          code: Math.floor(1000 + Math.random() * 9000),
          isActive: true,
          status: "PENDING",
          userID: userID,
          subscriptionID: subscriptionID,
        });
      setCreatingCode(false);
    }
  };

  return { createVerificationCode, creatingCode };
};
