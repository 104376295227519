import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { useLocation, useLocalStorage } from "react-use";

import { FirebaseContext } from "../../Firebase";
import { useSignInWithEmailLink } from "./hooks";

export default function LoginVerifyEmailLinkPage({ submitEmailValue }) {
  const firebase = React.useContext(FirebaseContext);
  const location = useLocation();
  const [
    localStorageEmail,
    setLocalStorageEmail,
    removeEmail,
  ] = useLocalStorage("LS_USER_EMAIL");
  const { loading, error } = useSignInWithEmailLink(
    location.href,
    localStorageEmail,
    removeEmail,
    firebase
  );
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="light" />
      </div>
    );
  }
  return (
    <Row>
      <Col>
        <div
          className="d-flex mt-4 mb-4"
          style={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {loading && <p>Loading...</p>}
          {error && <p>Error</p>}
          {!localStorageEmail && (
            <>
              <p
                style={{
                  fontSize: 2 + "rem",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: 1 + "rem",
                }}
              >
                Скорее всего вы открыли страницу в другом браузере, а не в том,
                в котором ранее вводили свой Email.
              </p>
              <p
                style={{
                  fontSize: 2 + "rem",
                  color: "#fff",
                  fontWeight: "bold",
                  marginBottom: -1 + "rem",
                }}
              >
                Для того, чтобы комфортно пользоваться подпиской, мы
                настоятельно рекомендуем, скопировать ссылку проверки Email и
                вставить ее в тот браузер, которым вы пользовались для отправки
                этой ссылки.
              </p>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
}
