import React, { useEffect, useState, useContext } from "react";
import moment from "moment";

//import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import Logo from "../../../logo.png";

const SubscriptionCard = ({
  data,
  verificationCode,
  createVerificationCode,
  loading,
  creatingCode,
}) => {
  const [activePeriodString, setActivePeriodString] = useState("");
  useEffect(() => {
    const start = moment.unix(data.subscriptionStartDate.seconds);
    const end = moment.unix(data.subscriptionEndDate.seconds);
    const formatStr = "DD.MM.YYYY";
    setActivePeriodString(`${end.format(formatStr)}`);
  }, [data]);
  const canRequestMeal = true;
  return (
    <div
      className="d-flex mb-4"
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        shadow
        style={{
          fontSize: 20 + "rem",
          color: "#fff",
          fontWeight: "bold",
          textShadow: "0px 2px 15px #85AD46",
          marginTop: -3 + "rem",
          marginBottom: -7 + "rem",
        }}
      >
        {data.subscriptionLeftCount}
      </div>
      <div
        style={{
          fontSize: 5 + "rem",
          color: "#fff",
          fontWeight: "bold",
          marginBottom: -1 + "rem",
        }}
      >
        ланчей
      </div>
      <p
        style={{
          fontSize: 1 + "rem",
          color: "#fff",
          fontWeight: "bold",
        }}
      >
        действуют до {activePeriodString}
      </p>
      <div style={{ marginTop: 1 + "rem", marginBottom: 3 + "rem" }}>
        <img src={Logo} width="178" height="15" />
      </div>
      {!verificationCode && (
        <Button
          variant="poke"
          onClick={() => createVerificationCode(data.id)}
          disabled={loading || creatingCode}
        >
          {(loading || creatingCode) && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          ПОЛУЧИТЬ КОД
        </Button>
      )}
      {verificationCode && verificationCode.length > 0 && (
        <div style={{ borderColor: "#fff", borderWidth: 2 + "px" }}>
          <p
            style={{
              fontSize: 24,
              color: "#fff",
              fontWeight: "bold",
              border: 2 + "px" + " solid white",
              paddingLeft: 2 + "rem",
              paddingRight: 2 + "rem",
              paddingTop: 0.5 + "rem",
              paddingBottom: 0.5 + "rem",
              borderRadius: 20 + "px",
            }}
          >
            {verificationCode[0].code}
          </p>
        </div>
      )}
      <div style={{ marginTop: 1 + "rem" }}>
        <a
          style={{ fontSize: 1 + "rem", color: "#4e4e4e" }}
          href="https://mypoke.ru"
          className="font-weight-bold"
        >
          mypoke.ru
        </a>
      </div>
    </div>
  );
};

export default SubscriptionCard;
