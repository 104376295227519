import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { useLocalStorage } from "react-use";
import Spinner from "react-bootstrap/Spinner";

import { FirebaseContext } from "../../Firebase";
import { useSubmitEmail } from "./hooks";
import Logo from "../../logo.png";
//import "./index.css";

export default function SignIn() {
  // const classes = useStyles();
  const firebase = React.useContext(FirebaseContext);
  const [email, setEmail] = React.useState("");
  const [emailValue, setEmailValue] = React.useState("");
  const [localStorageEmail, setLocalStorageEmail] = useLocalStorage(
    "LS_USER_EMAIL",
    email
  );
  const { submitting, success, error } = useSubmitEmail(
    email,
    firebase,
    setLocalStorageEmail
  );

  React.useEffect(() => {
    console.log(emailValue);
  }, [emailValue]);

  if (submitting) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="light" />
      </div>
    );
  }
  if (success) {
    return (
      <Row className="vh-100">
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 7, offset: 2 }}
          lg={{ span: 4, offset: 4 }}
        >
          <div className="mt-4">
            <p
              style={{
                fontSize: 2 + "rem",
                color: "#fff",
                fontWeight: "bold",
                marginBottom: 1 + "rem",
              }}
            >
              Email с уникальной ссылкой для входа отправлено...
            </p>
          </div>
        </Col>
      </Row>
    );
  }
  return (
    <Row className="vh-100">
      <Col
        xs={{ span: 10, offset: 1 }}
        md={{ span: 7, offset: 2 }}
        lg={{ span: 4, offset: 4 }}
      >
        <div className="d-flex flex-column   justify-content-between vh-100">
          <div className="d-flex flex-column">
            <div
              className="align-self-center"
              style={{ marginTop: 12 + "rem", marginBottom: 4 + "rem" }}
            >
              <img
                className="align-self-center"
                src={Logo}
                width="178"
                height="15"
                alt="logo"
              />
            </div>
            <Form
              className="form-signin"
              onSubmit={(e) => {
                console.log(emailValue);
                setEmail(emailValue);
                e.preventDefault();
              }}
            >
              <Form.Label className="sr-only">Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ваш email"
                required
                autoFocus
                onChange={(e) => setEmailValue(e.target.value)}
                value={emailValue}
              />

              <Button variant="poke" type="submit" block className="mt-4">
                ВОЙТИ
              </Button>
            </Form>
          </div>
          <div
            className="align-self-center"
            style={{ marginBottom: 4 + "rem" }}
          >
            <a
              style={{ fontSize: 1 + "rem", color: "#4e4e4e" }}
              href="https://mypoke.ru"
              className="font-weight-bold"
            >
              mypoke.ru v0.1
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}
